<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.topOffer') }}</h5>
                <Fieldset :legend="$t('sportsbets.addTopOffer')" :toggleable="true" :collapsed="true" class="p-col-12 p-mb-2">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-2 p-field p-grid">
                            <label class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">{{ $t('sportsbets.fixtureId') }}</label>
                            <div class="p-col-12 p-md-8">
                                <InputText style="width: 100%" type="text" v-model="fixtureIdAdd" />
                            </div>
                        </div>
                        <div class="p-col-12 p-md-2">
                            <span class="p-float-label">
                                <Dropdown id="dropdown" style="width: 100%" v-model="topOfferType" :options="topOfferTypes" optionLabel="name" />
                                <label for="dropdown">{{ $t('dropdown.placeholder.oddsBoost') }}</label>
                            </span>
                        </div>
                        <div class="p-col-12 p-md-2 p-md-offset-6 p-text-right">
                            <Button :label="$t('buttons.addEvent')" class="p-button-raised p-button-success" @click="addToTopOffer()" />
                        </div>
                    </div>
                </Fieldset>
                <DataTable :value="filteredData" :paginator="true" :rows="perPage" :loading="loading" ref="datatable" responsiveLayout="scroll" :paginatorBottom="true" stateStorage="session" stateKey="dt-state-crtoffer">
                    <template #header>
                        <div class="p-grid">
                            <div class="p-col-12 p-md-2">
                                <span class="p-float-label">
                                    <Dropdown :showClear="true" id="dropdown" style="width: 100%" v-model="topOfferFilter" :options="topOfferTournaments" optionLabel="name" />
                                    <label for="dropdown">{{ $t('dropdown.placeholder.oddsBoost') }}</label>
                                </span>
                            </div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column :header="$t('sportsbets.inOffer')">
                        <template #body="{ data }">
                            <Button :icon="'pi pi-' + inOfferIcon(data.inOffer)" :class="'p-button-rounded p-button-' + inOfferColor(data.inOffer) + ' p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column :header="$t('sportsbets.oddsUpdateStatus')">
                        <template #body="{ data }">
                            <Button :label="oddsStatusText(data.manualOdds)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-' + oddsStatusColor(data.manualOdds) + ' p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column field="offerNo" :header="$t('sportsbets.offerNo')" :sortable="true"></Column>
                    <Column field="fixtureId" :header="$t('sportsbets.fixtureId')" :sortable="true"></Column>

                    <Column field="fixtureDate" :header="$t('sportsbets.date')" :sortable="true">
                        <template #body="{ data }"> {{ formatCSDateAndTime(data.fixtureDate) }} </template>
                    </Column>
                    <Column field="competitor1" :header="$t('sportsbets.homeId')" :sortable="true"></Column>
                    <Column field="competitor2" :header="$t('sportsbets.awayId')" :sortable="true"></Column>
                    <Column field="name" :header="$t('sportsSettings.name')"> </Column>
                    <Column :header="$t('sportsbets.obbsBoostType')">
                        <template #body="{ data }">
                            <Button :label="oddsBoostText(data.tournamentId)" :class="'p-button-outlined p-mr-2 p-mb-2 p-button-info p-mr-2'" />
                        </template>
                        ></Column
                    >
                    <Column class="p-text-right">
                        <template #body="{ data }">
                            <Button
                                :title="$t('tooltip.details')"
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-warning p-mr-2"
                                @click="goto('eventDetails', { id: data.fixtureId, fixtureId: data.fixtureId, isTopOffer: true, fixturesActive: true, backRoute: crtRoute })"
                            />
                        </template>
                    </Column>
                    <Column class="p-text-right">
                        <template #body="{ data }">
                            <Button :title="$t('tooltip.remove')" icon="pi pi-times" class="p-button-rounded p-button-danger p-mr-2" @click="removeFromTopOffer(data.fixtureId)" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';

export default {
    name: 'oddsBoost',
    data() {
        var self = this;
        return {
            tableHeight: uiSettings.tableHeight,
            perPage: uiSettings.tableRows,
            SportsSettingsApi: new SportsSettingsApi(),
            showAddEvent: false,
            topOfferTypes: [
                { name: self.$t('sportsbets.topOffer0'), id: '0' },
                { name: self.$t('sportsbets.topOffer1'), id: '1' },
            ],
            topOfferTournaments: [
                { name: self.$t('sportsbets.topOffer0'), id: '544334' },
                { name: self.$t('sportsbets.topOffer1'), id: '544335' },
            ],
            topOfferType: { name: self.$t('sportsbets.topOffer0'), id: '0' },
            fixtureIdAdd: '',
            items: [],
            crtRoute: 'topOffer',
            loading: false,
            topOfferFilter: null,
        };
    },
    computed: {
        filteredData() {
            if (this.notNullOrEmptyObject(this.topOfferFilter)) {
                return this.items.filter((item) => {
                    return item.tournamentId === this.topOfferFilter.id;
                });
            } else {
                return this.items;
            }
        },
    },
    mounted() {
        this.getTC();
    },
    watch: {},
    methods: {
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        getTC() {
            this.loading = true;
            this.SportsSettingsApi.getTC()
                .then((response) => {
                    this.loading = false;
                    this.items = response.data.sort((a, b) => {
                        return a.tournamentId.localeCompare(b.tournamentId);
                    });
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        removeFromTopOffer(fixtureId) {
            this.SportsSettingsApi.removeTC(fixtureId)
                .then(() => {
                    this.getTC();
                })
                .catch(() => {
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        addToTopOffer() {
            this.errorText = '';
            this.showError = false;
            this.SportsSettingsApi.addTC(this.fixtureIdAdd, this.topOfferType.id)
                .then(() => {
                    this.getTC();
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.showToast('error', error.response.data);
                    } else {
                        this.showToast('error', this.$t('alerts.generalError'));
                    }
                });
        },
        inOfferIcon(inOffer) {
            return inOffer == true ? 'check' : 'times';
        },
        inOfferColor(inOffer) {
            return inOffer == true ? 'success' : 'danger';
        },
        oddsStatusColor(manualOdds) {
            return manualOdds == true ? 'warning' : 'success';
        },
        oddsStatusText(manualOdds) {
            return manualOdds == true ? 'manual' : 'auto';
        },
        oddsBoostText(tournamentId) {
            return tournamentId == '544334' ? this.$t('sportsbets.topOffer0') : this.$t('sportsbets.topOffer1');
        },
    },
};
</script>
                