import axios from 'axios'
import settings from '../settings/apis.js'

class SportsSettingsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].sportsApiUrl + "/settings/",
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getSports(params) {
        return this.Api.get("sports", {params});
    }
    getCategories(params) {
        return this.Api.get("categories", {params});
    }
    getTournaments(params) {
        return this.Api.get("tournaments", {params});
    }
    getMarkets(params) {
        return this.Api.get("markets", {params});
    }
    getSpecialMarkets(params) {
        return this.Api.get("specialMarkets", {params});
    }
    getAllSpecialMarkets(sportId) {
        return this.Api.get("getAllSpecialMarkets/" + sportId);
    }
    getDisplayMarkets(params) {
        return this.Api.get("displayMarkets", {params});
    }
    getGroups(params) {
        return this.Api.get("groups", {params});
    }
    getCompetitors(params) {
        return this.Api.get("competitors", {params});
    }

    getSport(sportId) {
        return this.Api.get('getSport/' + sportId)
    }
    updateSport(sport) {
        var sportObj = {
            DisplayName: sport.displayName,
            ExternalId: sport.externalId,
            Id: sport.id,
            Name: sport.name,
            SortOrder: sport.sortOrder
        }
        return this.Api.post('updateSport', sportObj)
    }
    getCategory(categoryId) {
        return this.Api.get('getCategory/' + categoryId)
    }
    getTournament(tournamentId) {
        return this.Api.get('getTournament/' + tournamentId)
    }
    updateCategory(category) {
        var categoryObj = {
            DisplayName: category.displayName,
            ExternalId: category.externalId,
            Id: category.id,
            Name: category.name,
            SportId: category.sportId,
            SortOrder: category.sortOrder
        }
        return this.Api.post('updateCategory', categoryObj)
    }
    updateTournament(tournament) {
        var tournamentObj = {
            DisplayName: tournament.displayName,
            ExternalId: tournament.externalId,
            Id: tournament.id,
            Name: tournament.name,
            SportId: tournament.sportId,
            CategoryId: tournament.categoryId,
            SortOrder: tournament.sortOrder,
            MarketTemplate: tournament.marketTemplate,
            PrintTemplate: tournament.printTemplate,
            OddsTemplate: tournament.oddsTemplate
        }
        return this.Api.post('updateTournament', tournamentObj)
    }
    getAllSports() {
        return this.Api.get('getAllSports/')
    }
    getAllCategories(sportId) {
        return this.Api.get('getAllCategories/' + sportId)
    }
    getAllTournaments(sportId, categoryId) {
        return this.Api.get('getAllTournaments/' + sportId + '/' + categoryId)
    }
    getAllGroups() {
        return this.Api.get('getAllGroups/')
    }
    getMarket(marketId) {
        return this.Api.get('getMarket/' + marketId)
    }
    getSpecialMarket(marketId) {
        return this.Api.get('getSpecialMarket/' + marketId)
    }
    updateMarket(market) {
        var marketObj = {
            MarketName: market.marketName,
            Groups: market.groups,
            DisplayName: market.displayName,
            MarketId: market.marketId,
            IncludesOutcomesOfType: market.includesOutcomesOfType,
            OutcomeType: market.outcomeType,
            Specifiers: market.specifiers,
            Outcomes: market.outcomes,
            Attributes: market.attributes,
            Id: market._id,
            SortNo: market.sortNo,
            DisplayCols: market.displayCols,
            CanBeMixed:market.canBeMixed
        }
        return this.Api.post('updateMarket', marketObj)
    }
    updateSpecialMarket(market) {
        var marketObj = {
            DisplayName: market.displayName,
            Groups: market.groups,
            MarketId: market.marketId,
            HasSpecifier: market.hasSpecifier,
            Outcomes: market.outcomes,
            SortNo: market.sortNo,
            DisplayCols: market.displayCols,
            HasPlayers: market.hasPlayers,
            SportId: market.sportId,
            TemplateId: market.templateId
        }
        return this.Api.post('updateSpecialMarket', marketObj)
    }
    getSpecialMarketsTemplates() {
        return this.Api.get('getSpecialMarketsTemplates')
    }
    getSpecialMarketsGroups(sportId) {
        return this.Api.get('getSpecialMarketGroups/' + sportId)
    }
    getSpecialMarketsFromGroup(marketsIds) {
        var request = {
            MarketsIds: marketsIds
        }
        return this.Api.post('getSpecialMarketsFromGroup', request)
    }
    addSpecialsGroup(request) {
        return this.Api.post('addNewSpecialsGroup', request)
    }
    getGroup(groupId) {
        return this.Api.get('getGroup/' + groupId)
    }
    updateGroup(group) {
        var groupObj = {
            Name: group.name,
            DisplayName: group.displayName,
            MarketIds: group.marketIds,
            SortOrder: group.sortOrder
        }
        return this.Api.post('updateGroup', groupObj)
    }
    getMarketsForGroups(marketIds) {
        return this.Api.get('getMarketsForGroups/' + marketIds)
    }
    getCompetitor(competitorId) {
        return this.Api.get('getCompetitor/' + competitorId)
    }
    updateCompetitor(competitor) {
        var competitorObj = {
            Name: competitor.name,
            DisplayName: competitor.displayName,
            CompetitorId: competitor.competitorId
        }
        return this.Api.post('updateCompetitor', competitorObj)
    }
    getDisplayMarket(marketId) {
        return this.Api.get('getDisplayMarket/' + marketId)
    }
    updateDisplayMarket(market) {
        var marketObj = {
            DisplayName: market.displayName,
            MarketId: market.marketId,
            Outcomes: market.outcomes,
            Id: market._id,
            SortNo: market.sortNo,
            DisplayCols: market.displayCols
        }
        return this.Api.post('updateDisplayMarket', marketObj)
    }
    getMarketTemplates(sportId) {
        return this.Api.get("getMarketTemplates/" + sportId);
    }
    getPrintTemplates() {
        return this.Api.get("getPrintTemplates");
    }
    getOddsTemplates() {
        return this.Api.get("getOddsTemplates");
    }
    getOddsTemplate(templateId) {
        return this.Api.get("getOddsTemplate/" + templateId);
    }
    getMarketTemplatesWithTours() {
        return this.Api.get("getMarketTemplatesWithTours");
    }
    getOddsTemplatesWithTours() {
        return this.Api.get("getOddsTemplatesWithTours");
    }
    getTC() {
        return this.Api.get('getTC')
    }
    addTC(fixtureId, topOfferType) {
        return this.Api.get('addTC/' + fixtureId + '/' + topOfferType)
    }
    removeTC(fixtureId) {
        return this.Api.get('removeTC/' + fixtureId)
    }
    getPrintSelection(sportId) {
        return this.Api.get('getPrintSelectionOffer/' + sportId);
    }
    updateOddsTemplate(template) {
        var templateObj = {
            _id: template._id,
            Name: template.name,
            Intervals: template.intervals
        }
        return this.Api.post('updateOddsTemplate', templateObj);
    }
    changeFixtureTournament(fixtureId, tournamentId) {
        var changeObj = {
            FixtureId: fixtureId,
            TournamentId: tournamentId
        }
        return this.Api.post('changeFixtureTournament', changeObj);
    }
    getPopularLeagues() {
        return this.Api.get('getPopularLeagues');
    }
    removeFromPopulareLeagues(leagueId) {
        return this.Api.get('removeFromPopularLeagues/' + leagueId);
    }
    addPopularLeague(request) {
        return this.Api.post('addPopularLeague', request);
    }
    updatePopularLeague(leagueObject) {
        var request = {
            TournamentId: leagueObject.tournamentId,
            CategoryId: leagueObject.categoryId,
            SportId: leagueObject.sportId,
            SortOrder: leagueObject.sortOrder,
            Name: leagueObject.name
        }
        return this.Api.post('updatePopularLeague', request);
    }
    getLiveOfferNumber() {
        return this.Api.get('getliveOfferNumber');
    }
    freeLiveOfferNumber(number){
        return this.Api.get('freeLiveOfferNumber/'+number)
    }
}

export {SportsSettingsApi}
